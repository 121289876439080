const defaultSettings = {
  score: 0,
  question: 0,
  overlay: false,
  videos: false,
  challenge: { active: false, name: '', score: 0 },
};

export const reducer = (status, action) => {
  switch (action.type) {
    case 'open_overlay':
      return {
        ...status,
        overlay: true,
      };
    case 'close_overlay':
      return {
        ...status,
        overlay: false,
      };
    case 'open_videos':
      return {
        ...status,
        videos: true,
      };
    case 'close_videos':
      return {
        ...status,
        videos: false,
      };
    case 'start_challenge':
      return {
        ...status,
        challenge: {
          active: true,
          name: action.payload.name,
          score: action.payload.score,
        },
      };
    case 'answer_correct':
      return {
        ...status,
        score: status.score + 1,
      };
    case 'answer_wrong':
      return {
        ...status,
      };
    case 'next_question':
      return {
        ...status,
        question: status.question + 1,
      };
    case 'prev_question':
      return {
        ...status,
        question: status.question - 1,
      };

    default:
      return status;
  }
};

export const initialState = () => {
  return defaultSettings;
};
