import React from 'react';
import { reducer, initialState } from './reducer';
import PropTypes from 'prop-types';

export const StatusContext = React.createContext({
  status: initialState(),
  dispatch: () => null,
});

export const StatusProvider = ({ children }) => {
  const [status, dispatch] = React.useReducer(reducer, initialState());
  return (
    <StatusContext.Provider value={[status, dispatch]}>
      {children}
    </StatusContext.Provider>
  );
};

StatusProvider.propTypes = {
  children: PropTypes.node,
};

StatusProvider.defaultProps = {
  children: null,
};
